<template>
  <div>
    <template>
      <ul class="nav">
        <li :class="[lang]">{{ $t("orderInfo") }}</li>
      </ul>
    </template>
    <a-spin size="large" :spinning="loading">
      <div class="wrap-table">
        <a-table
          class="custom-scrollbar"
          size="middle"
          :columns="columns"
          :rowKey="(record, index) => index"
          :data-source="tableData"
          :pagination="false"
          :row-class-name="
            (_record, index) => (index % 2 !== 1 ? 'table-bg' : null)
          "
          :customRow="
            (record) => {
              return {
                on: {
                  click: () => goDetail(record),
                },
              };
            }
          "
        >
          <template slot="payType" slot-scope="text, record" class="arial">
            <span>{{
              record.duration_type == 2
                ? $t("AnnualPayment")
                : record.duration_type == 3
                ? $t("MonthlyPayment")
                : "免费"
            }}</span>
          </template>

          <template slot="action" slot-scope="text, record" class="arial">
            <span v-if="record.linkedin_id">{{ record.linkedin_email }}</span>
            <a-button
              v-else
              type="primary"
              @click="bindHandler(record)"
              size="small"
              style="height: 26px; font-size: 12px"
              >{{ $t("GotoLinkAccount") }}</a-button
            >
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: true,
      tableData: [],
      loading: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/sales/salesReps/campaignDetail") {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
      console.log(to, from);
    },
    lang() {
      this.getData();
    },
  },
  computed: {
    currentPath: {
      get() {
        return this.$route.path;
      },
    },
    columns: {
      get() {
        let that = this;
        return [
          {
            title: that.$t("SubscriptionDate"),
            dataIndex: "create_date",
            align: "center",
            width: 200,
          },
          {
            title: that.$t("SubscriptionPlan"),
            dataIndex: "subscription_plan_name",
            align: "center",
          },
          {
            title: that.$t("SubscriptionType"),
            dataIndex: "duration_type",
            align: "center",
            // scopedSlots: {
            //   customRender: "payType",
            // },
          },
          {
            title: that.$t("Price"),
            dataIndex: "amount",
            align: "center",
          },

          {
            title: that.$t("LinkAccount"),
            align: "center",
            fixed: "right",
            width: 200,
            scopedSlots: {
              customRender: "action",
            },
          },
        ];
      },
    },
  },
  i18n: require("../i18n"),
  created() {
    //this.showMenu = this.showMenu2();
    this.getData();
  },
  methods: {
    bindHandler(record) {
      console.log(record);
      let { id, campaign_type } = record;
      this.$router.push({
        path: "/setting/bindAccount",
        query: { id, campaign_type },
      });
    },
    getData() {
      this.loading = true;
      this.$axios("/payment/order_list", {}, "post").then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    goback() {
      this.$router.go(-1);
    },
    showMenu2() {
      if (this.$route.path == "/sales/salesReps/dialogue") {
        return false;
      } else if (this.$route.path == "/sales/salesReps/createCampaign") {
        return false;
      } else if (this.$route.path == "/sales/salesReps/campaignDetail") {
        return false;
      } else {
        return true;
      }
    },
    goDetail () {}
  },
};
</script>
<style scoped lang="less">
.wrap-table {
  margin: 80px 30px 30px 30px;
  padding: 35px 30px 30px 30px;
  background: #fff;
  min-height: 690px;
}
.nav {
  width: 100%;
  padding-top: 7px;
  padding-left: 30px;
  border-top: #d1d1d1 solid 1px;
  li {
    height: 42px;
    line-height: 42px;
    padding: 0 12px;
    margin-right: 88px;
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    a {
      color: #000;
    }
    border-bottom: #fff 2px solid;
  }
  .selected {
    border-bottom: #0e756a 2px solid;
    a {
      font-weight: bold;
    }
  }
  background: #ffffff;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
}
.nav,
.back {
  left: 254px;
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 200;
}
.back {
  border-top: #d1d1d1 solid 1px;
  color: #0e756a;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  background: #fff;
}
</style>
